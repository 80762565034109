<template>
<v-container fluid>
	<v-row align="center">
		<v-col class="d-flex"
					 cols="12"
					 sm="6">
			<v-select :items="items"
								label="Standard"></v-select>
		</v-col>

		<v-col class="d-flex"
					 cols="12"
					 sm="6">
			<v-select :items="items"
								filled
								label="Filled style"></v-select>
		</v-col>

		<v-col class="d-flex"
					 cols="12"
					 sm="6">
			<v-select :items="items"
								label="Outlined style"
								outlined></v-select>
		</v-col>

		<v-col class="d-flex"
					 cols="12"
					 sm="6">
			<v-select :items="items"
								label="Solo field"
								solo></v-select>
		</v-col>
	</v-row>
</v-container>
</template>

<script>
const eurocurrency = new Intl.NumberFormat('fr-FR', {
	style: 'currency',
	currency: 'EUR',
	minimumFractionDigits: 2
});


export default {
	name: 'account',
	components: {},
	props: {},
	data: () => ({
		items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
